<template>
  <div class="container-fluid header">
    <div class="row">
      <div class="col col-lg-6 col-md-6 col-sm-6">
        <div class="logo">
          <img src="../image/logo.png" alt="Logo" />
        </div>
      </div>
      <div class="col col-lg-6 col-md-6 col-sm-6">
        <div class="row">
          <div class="col col-lg-10">
            <div class="userName">
              <h3>ADMIN</h3>
            </div>
          </div>
          <div class="col col-lg-2">
            <div class="userNameImg">
              <img src="../image/admin.png" alt="Admin" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <!-- side button -->
      <div class="col col-lg-3 col-md-3 col-sm-12 mt-5">
        <router-link style="text-decoration: none" to="/home/dashboard">
          <button
            class="btnDashboard"
            v-on:click="showDashboard"
            v-bind:class="dashboardClicked && 'bold'"
          >
            DASHBOARD
          </button>
        </router-link>

        <router-link style="text-decoration: none" to="/home/request-list">
          <button class="btnDashboard" v-on:click="showRequestList" v-bind:class="reqListClicked && 'bold'">
            REQUEST LIST
          </button>
        </router-link>

        <button class="btnDashboard" v-on:click="logout">LOGOUT</button>
      </div>

      <div class="col col-lg-9 col-md-9 col-sm-12 mt-5">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import "../style/style.css";
import store from "../store";

export default {
  name: "Home",
  
  metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'SICPA - CRM',
  },

  data() {
    return {
      dashboardClicked: true,
      generateReqClicked: false,
      reqListClicked: false,
      inventoryDetailsClicked: false,
    };
  },
  methods: {
    logout() {
      store.state.token = "";
      this.$router.push({ name: "Login" });
    },
    showGenerateReq() {
      this.dashboardClicked = false;
      this.generateReqClicked = true;
      this.reqListClicked = false;
      this.inventoryDetailsClicked = false;
    },
    showDashboard() {
      this.dashboardClicked = true;
      this.generateReqClicked = false;
      this.reqListClicked = false;
      this.inventoryDetailsClicked = false;
    },
    showRequestList() {
      this.dashboardClicked = false;
      this.generateReqClicked = false;
      this.reqListClicked = true;
      this.inventoryDetailsClicked = false;
    },
    showInventoryDetail() {
      this.dashboardClicked = false;
      this.generateReqClicked = false;
      this.reqListClicked = false;
      this.inventoryDetailsClicked = true;
    },
  },
};
</script>

<style scoped>
.btnDashboard {
  display: block;
  width: 70%;
  margin: auto;
  padding: 5px;
  padding-left: 5%;
  text-align: left;
  font-size: 10pt;
  background-color: rgb(240, 235, 235);
  border: 1px solid silver;
  color: black;
}

.btnDashboard:hover {
  background-color: darkgray;
}

.userName {
  margin: 60px 0 0 0;
  text-align: right;
}

.userNameImg {
  margin: 40px 0 0 0;
  width: 50%;
}

.userNameImg img {
  width: 100%;
  padding: 0;
}
</style>
