<template>
  <div class="container">
    <h3>REQUEST DETAIL'S:</h3>
      <!-- Error -->
      <div
        v-show="errorFlag"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {{ errorMsg }}
      </div>

    <div class="row">
      <div class="col col-lg-12 col-md-12 col-sm-12">
        <div class="requestDetailContainer">
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <h4>AUTHENTIK PO #:</h4>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <h4>{{ request.id }}</h4>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12"></div>
          </div>
          
          <div class="row mt-4">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>REQUEST DATE:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ new Date(request.dateAdded).toDateString() }}</p>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>DELIVERY DATE:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ new Date(request.deliveryDate).toDateString() }}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>QTY OF LABEL:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ request.quantity}}</p>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>PRICE OF LABEL:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ request.priceOfLabel }} Rupees</p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>CHEVRON PO #:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ request.chevronPONo}}</p>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-5 col-md-6 col-sm-12">
                  <p>LABEL STATUS:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12">
                  <p class="bold">{{ request.status }}</p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row">
            <div class="col col-lg-8 col-md-8 col-sm-12">
              <div class="row">
                <div class="col col-lg-3 col-md-3 col-sm-12">
                  <p>NOTE:</p>
                </div>
                <div class="col col-lg-7 col-md-6 col-sm-12 notes">
                  {{ request.notes }}
                </div>
              </div>
            </div>
            
            <div class="col col-lg-4 col-md-4 col-sm-12 pt-5" v-if="canDownload">
              <div class="row">
                <div class="col col-lg-12 col-md-12 col-sm-12 mb-3">
                    <button class="btn btn-primary center-block-btn" v-on:click="downloadPackage" :disabled="downloading">
                      DOWNLOAD PACKAGE
                    </button>
                </div>
                <div class="col col-lg-12 col-md-12 col-sm-12">
                    <button class="btn btn-secondary center-block-btn" 
                      v-on:click="gotoUploadFile()">
                        UPLOAD PRINTED CODE FILE</button>
                </div>
              </div>
            </div>
          </div>

          </div>
        </div>
      </div>
    </div>

  <div v-if='showFrame'>
    <iframe :src="frameSrc"></iframe>
  </div>

</template>

<script>
import ChevronApi from "../lib/chevron-api"
import "../style/style.css"
import store from "../store";

export default {
  name: "RequestDetails",
  
  data() {
    return {
      id: this.$route.params.id,
      request: {},
      actionHistory: [],
      errorFlag: false,
      errorMsg: "",
      downloading: false,
    }
  },
  
  mounted() {
    const api = new ChevronApi()
    api.getRequestDetailFromID(this.id)
      .then((response) => {
        this.request = response.request
        this.actionHistory = response.history
      })
      .catch((error) => {
        this.errorFlag = true;
        this.errorMsg = error;
      })
  },

  computed: {
    canDownload() {
      return this.request.status != 'Pending' && this.request.status != 'Error';
    },

    canDeliver() {
      return this.request.status != 'Pending' && this.request.status != 'Error';
    }
  },

  methods: {
    downloadPackage() {
      const api = new ChevronApi();
      this.downloading = true;

      this.frameSrc = api.getBase() + `/chevron/download/package?poId=${this.id}&token=${store.state.token}`;
      this.showFrame = true;

      // this.downloading = true;

      // const api = new ChevronApi();
      // api.downloadPackage(this.id)
      //   .then((response) => {
      //     this.saveFile(response, `${this.id}.zip`);
      //     this.downloading = false;
      //   })
      //   .catch((error) => {
      //     this.errorFlag = true;
      //     this.errorMsg = error;
      //     this.downloading = false;
      //   });
    },

    gotoUploadFile() {
      this.$router.push(`/home/upload-file/${this.id}`)
    },

    saveFile(response, defaultFilename) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      var filename = response.headers['x-file-name'];
      if (filename === undefined) {
        filename = defaultFilename;
      }
      link.setAttribute('download', filename);
      
      document.body.appendChild(link);
      link.click();
    },
  }
};

</script>

<style scoped>
.requestDetailContainer{
  width: 100%;
  padding: 30px;
  border: 1px solid black;
  margin-bottom: 50px;
}
.center-block-btn{
  display: block;
  margin: auto;
}
textarea{
  width: 100%;
}
.btn{
  margin-left: 3%;
}
.notes {
  border: 1px solid black;
  min-height: 10em;
}
</style>
