<template>
  <div class="container">
    <h3>UPLOAD FILE:</h3>
      <!-- Error -->
      <div
        v-show="errorFlag"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {{ errorMsg }}
      </div>

    <div class="row">
      <div class="col col-lg-12 col-md-12 col-sm-12">
        <div class="requestDetailContainer">
          <div class="row">
            <div class="col col-lg-6 col-md-6 col-sm-12">
              <div class="row">
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <h4>AUTHENTIK PO #:</h4>
                </div>
                <div class="col col-lg-6 col-md-6 col-sm-12">
                  <h4>{{ request.id }}</h4>
                </div>
              </div>
            </div>
            <div class="col col-lg-6 col-md-6 col-sm-12"></div>
          </div>
          
      <form enctype="multipart/form-data" onsubmit="return false;" novalidate v-if="isInitial || isSaving || isSelected">
        <div class="dropbox">
          
          <input type="file" :name="uploadFieldName" 
            :disabled="isSaving" 
            @change="filesChange($event.target.name, $event.target.files); 
            fileCount = $event.target.files.length"
            accept="text/csv" 
            class="input-file">

            <p v-if="isInitial">
              Drag csv file here to begin <br> or click to browse
            </p>
            <p v-if="isSaving">
              Uploading {{ fileCount }} files...
            </p>
            <p v-if="isSelected">
              Selected File: {{ this.selectedFile }}
              <br />
              <br />
              Click on Upload File to start uploading
            </p>
        </div>

        <div class="row">
            <div class="col-12 mt-3">
                <button class="btn btn-primary center-block-btn" v-on:click="uploadFile">
                    UPLOAD FILE
                </button>
            </div>
        </div>
      </form>


<!--SUCCESS-->
      <div v-if="isSuccess">
        <b>{{ selectedFile }} has been successfully uploaded and is being processed.</b>
      </div>
      <!--FAILED-->
      <div v-if="isFailed">
        <h3>Uploaded failed.</h3>
        <p>
          <a href="javascript:void(0)" @click="reset()">Try again</a>
        </p>
        <pre>{{ errorMsg }}</pre>
      </div>


          </div>
        </div>
      </div>
    </div>

</template>

<script>
    console.log('page loaded');

  import ChevronApi from "../lib/chevron-api"
  import "../style/style.css"

  const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3, STATUS_SELECTED = 4;

  export default {
    name: 'uploadFile',
    
    data() {
      return {
        uploadedFiles: [],
        currentStatus: STATUS_INITIAL,
        uploadFieldName: 'printedCodes',
        id: this.$route.params.id,
        errorFlag: false,
        errorMsg: "",
        request: {},
        formData: null,
        selectedFile: null,
      }
    },

    computed: {
      isInitial() {
        return this.currentStatus === STATUS_INITIAL;
      },
      isSaving() {
        return this.currentStatus === STATUS_SAVING;
      },
      isSuccess() {
        return this.currentStatus === STATUS_SUCCESS;
      },
      isFailed() {
        return this.currentStatus === STATUS_FAILED;
      },
      isSelected() {
        return this.currentStatus === STATUS_SELECTED;
      }
    },

    mounted() {
        this.reset();
        const api = new ChevronApi()
    
        api.getRequestDetailFromID(this.id)
            .then((response) => {
                this.request = response.request
            })
            .catch((error) => {
                this.errorFlag = true;
                this.errorMsg = error;
            })
    },

    methods: {
     
      reset() {
        // reset form to initial state
        this.currentStatus = STATUS_INITIAL;
        this.uploadedFiles = [];
        this.errorMsg = null;
        this.errorFlag = false;
      },

      uploadFile() {
        if (this.formData == null)
            alert('Please select a file to upload');
        else
            this.save(this.formData);
      },

      save(formData) {
        // upload data to the server
        this.currentStatus = STATUS_SAVING;

        console.log(formData)

        const api = new ChevronApi()

        api.upload(this.id, formData)
          .then(x => {
            this.uploadedFiles = [].concat(x);
            this.currentStatus = STATUS_SUCCESS;
          })
          .catch(err => {
            this.currentStatus = STATUS_FAILED
            this.errorFlag = true
            this.errorMsg = `Error (${err.code}): ${err.message}`
          });
      },

      filesChange(fieldName, fileList) {
        // handle file changes
        this.formData = new FormData();

        if (!fileList.length) return;

        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            this.formData.append(fieldName, fileList[x], fileList[x].name);
          });

        this.selectedFile = fileList[0].name
        this.currentStatus = STATUS_SELECTED;
      }
    },
}

</script>

<style scoped>
.requestDetailContainer{
  width: 100%;
  padding: 30px;
  border: 1px solid black;
  margin-bottom: 50px;
}
.center-block-btn{
  display: block;
  margin: auto;
}
textarea{
  width: 100%;
}
.btn{
  margin-left: 3%;
}
.rlabel {
  text-align: right;
}
  .dropbox {
    outline: 2px dashed grey; /* the dash box */
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px; /* minimum height */
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: lightblue; /* when mouse over to the drop zone, change color */
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
</style>