<template>
  <div class="container">
    <h3>REQUEST LIST:</h3>
    <div class="row">
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Start Date:</h6>
        <input type="date" v-model="startDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>End Date:</h6>
        <input type="date" v-model="endDate" />
      </div>
      <div class="col col-lg-4 col-md-4 col-sm-12">
        <h6>Status:</h6>
        <label for="status"></label>
        <select name="status" id="status" v-model="status">
          <option value=""></option>
          <option value="Ready">Ready</option>
          <option value="InProgress">InProgress</option>
          <option value="Printed">Printed</option>
          <option value="Delivered">Delivered</option>
          <option value="Cancelled">Cancelled</option>
        </select>
      </div>

      <div class="row">
        <div class="col col-lg-4 col-md-4 col-sm-12"></div>
        <div class="col col-lg-4 col-md-4 col-sm-12"></div>
        <div class="col col-lg-4 col-md-4 col-sm-12">
          <button v-on:click="filterRequest" class="btn btn-primary searchBtn">
            Search
          </button>
        </div>
      </div>

      <!-- Error -->
      <div
        v-show="errorFlag"
        class="alert alert-danger alert-dismissible fade show"
        role="alert"
      >
        {{ errorMsg }}
      </div>

      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12">
          <div class="recentReq row">
            <div class="col col-lg-11 col-md-11 col-sm-11">
              <h5>RECENT REQUESTS</h5>
            </div>
            <div class="col col-lg-1 col-md-1 col-sm-1">
              <div class="setting">
                <img
                  v-on:click="clickRequest"
                  src="../image/setting.png"
                  alt="img"
                />
              </div>
            </div>

            <div class="col col-lg-12 col-md-12 col-sm-12 listTbl">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.</th>
                      <th>Chevron PO</th>
                      <th>Added Date</th>
                      <th>Quantity</th>
                      <th>Deliver Date</th>
                      <th>Status</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr v-for="r in poRequests" v-bind:key="r.id">
                      <td>{{ r.serialNo }}</td>
                      <td class="bold alignLeft">{{ r.chevronPONo }}</td>
                      <td>{{ new Date(r.dateAdded).toDateString() }}</td>
                      <td>{{ r.quantity }}</td>
                      <td>{{ new Date(r.deliveryDate).toDateString() }}</td>
                      <td>{{ r.status }}</td>
                      <td>
                        <button v-on:click="requestDetail(r.id)" class="btn bg-dark-grey" >Details </button>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>

            <ul class="pagination just-center" v-show="lastPageNoToShow > 1">
              <li class="page-item" :class="index === currentPage ? 'active' : ''" v-for="index in lastPageNoToShow" :key="index" >
                <a v-on:click="getPagedRequest(index)" class="page-link">{{ index }}</a>
              </li>
            </ul>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronApi from "../lib/chevron-api"
import "../style/style.css"

export default {
  name: "RequestList",

  data() {
    return {
      poRequests: [],
      startDate: "",
      endDate: "",
      status: undefined,
      totalRequests: "0",
      currentPage: 1,
      errorFlag: false,
      errorMsg: "",
      filterError: "Invalid Filter Error",
      recordPerPage: 7,
      startPageNoToShow: 1,
    };
  },

  mounted() {
    var today = new Date()
    today.setMonth(today.getMonth() - 1)
    var startDate = today.toISOString().split("T")[0]
    today.setMonth(today.getMonth() + 2)
    var endDate = today.toISOString().split("T")[0]
    this.startDate = startDate
    this.endDate = endDate

    this.getPagedRequest(this.currentPage, this.startDate, this.endDate, this.status)
  },

  computed: {
    lastPageNoToShow() {
      var count = 10;
      if (this.totalRequests === 0) {
        return 1;
      }
      var last = this.startPageNoToShow + 10;
      var lastCount = last * this.recordPerPage;
      if (lastCount > this.totalRequests) {
        count = Math.ceil(this.totalRequests / this.recordPerPage) - this.startPageNoToShow + 1;
      }
      return count;
    },
  },

  methods: {
    getPagedRequest(index) {
      const api = new ChevronApi();

      api.getPagedRequestList(index - 1, this.startDate, this.endDate, this.status)
        .then((response) => {
          this.errorFlag = false;
          this.poRequests = response.items;
          this.totalRequests = response.totalRecords;
          this.currentPage = index;
          
          var i = (this.currentPage - 1) * 7 + 1;
          for (var r of this.poRequests) {
            r.serialNo = i++;
          }
        })
        .catch((error) => {
          this.errorMsg = error;
          this.errorFlag = true;
        });
    },
 
    requestDetail(id) {
      this.$router.push(`/home/request-detail/${id}`)
    },
    
    filterRequest() {
      if (this.endDate < this.startDate) {
        this.errorMsg = this.filterError
        this.errorFlag = true
      } 
      else {
        this.getPagedRequest(this.currentPage);
      }
    },
  },
};
</script>

<style scoped>
select {
  width: 150px;
}
.searchBtn {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 95px;
}
.recentReq {
  border: 1px solid black;
  color: gray;
  padding: 20px;
  margin-bottom: 30px;
}
.setting {
  margin: 0;
  padding: 0;
  width: 50%;
  margin-right: 0%;
}
.setting img {
  width: 100%;
  cursor: pointer;
}
.listTbl {
  margin-top: 0px;
}

.abc {
  background-color: greenyellow;
}

@media (max-width: 1200px) {
  .setting {
    width: 70%;
  }
}
@media (max-width: 991px) {
  .setting {
    width: 150%;
  }
  input,
  select {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .setting {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .setting {
    width: 20%;
  }
}
</style>
