import { createWebHistory, createRouter } from 'vue-router';
import Login from './components/Login.vue';
import Home from './components/Home.vue';
import Dashboard from './components/Dashboard.vue';
import RequestList from './components/RequestList.vue';
import RequestDetails from './components/RequestDetails.vue';
import UploadFile from './components/UploadFile.vue';

const routes = [
    {
        name: 'Login',
        path: '/',
        component: Login
    },
    {
        name: 'Home',
        path: '/home',
        component: Home,
        children: [
            {
                path: '/home/dashboard',
                component: Dashboard
            },
            {
                path: '/home/request-list',
                component: RequestList,
            },
            {
                path: '/home/request-detail/:id',
                component: RequestDetails,
            },
            {
                path: '/home/upload-file/:id',
                component: UploadFile,
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;